<template>
	<div>
		<v-layout class="min-height-57px">
			<v-flex class="font-level-3-bold my-auto d-flex justify-space-between w-100">
				<div class="detail-svg-icon mr-2 d-flex align-center">
					
					<inline-svg height="20" width="20" :src="$assetURL('media/custom-svg/truck-delivery.svg')" />
					
					<template> <span class="my-auto ml-2">Equipments</span> </template>
				</div>
			</v-flex>
		</v-layout>
		<div
			v-if="!pageLoading"
			class="overflow-y"
			style="max-height: calc(100vh - 337px); min-height: 200px"
		>
			<v-simple-table class="bt-table listing-table table-head-sticky">
				<thead>
					<tr>
						<th class="simple-table-th"> #</th>
						<th class="simple-table-th">Equipment</th>
						<th class="simple-table-th">Description</th>
						<!-- <th class="simple-table-th">Quantity</th> -->
						<th class="simple-table-th">Request Quantity</th>
					</tr>
				</thead>
				<tbody v-if="lineItems.length">
					<tr class="cursor-pointer" v-for="(row, index) in lineItems" :key="index">
						<td class="td_column cursor-default simple-table-td">
							{{index+1}}
							<!-- <Chip
								v-if="row"
								tooltip
								tooltip-text="Quotation #"
								:text="row.quotation_barcode"
								color="cyan"
							></Chip>
							 -->
						</td>
						<td class="td_column cursor-default simple-table-td">
							<ShowValue class="mr-1" :object="row" object-key="item_name" label="Name"></ShowValue>
						</td>
						<td>
							<ShowValue class="mr-1" :object="row" object-key="description" label="description"></ShowValue>
						</td>
						<!-- <td class="td_column cursor-default simple-table-td">
							<ShowValue class="mr-1" :object="row" object-key="quantity" label="Quantity"></ShowValue>
						</td> -->
							<td class="td_column cursor-default simple-table-td">
							<ShowValue class="mr-1" :object="row" object-key="request_quantity" label="Request"></ShowValue>
						</td>
				
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no line items at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue";
//import ShowPrice from "@/view/components/ShowPrice";
// import Chip from "@/view/components/Chip";
// import { SET_ERROR /* SET_MESSAGE */ } from "@/core/services/store/common.module";
export default {
	name: "lineItems-listing",
	title: "lineItems Listing",
	// mixins: [ListingMixin],
	data() {
		return {
			pageLoading: false,
		};
	},
	props: {
		lineItems: {
			type: Array,
			default: () => {return []},
		},
	},
	components: {
	//	ShowPrice,
		ShowValue,
		// Chip,
	},
	
	mounted() {
		this.internal = true;

		//this.lineItems = this.project.line_items ?this.project.line_items: [];
		//console.log(this.project);

	},
};
</script>
