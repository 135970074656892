<template>
	<v-sheet class="project" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<v-layout class="justify-content-between align-items-center">
						<v-flex md7 class="d-flex flex-wrap align-items-center header-heading">
							<p class="my-auto font-level-3-bold text-truncate" :style="`font-size: 20px`">
								<ShowValue :object="projectTransfer" object-key="transfer_projects_barcode" label="Project Transfer ID"></ShowValue>
							</p>
							<div id="header-status">
								<!-- <ShowBarcode
									:object="quotation"
									object-key="barcode"
									tooltip
									tooltip-text="quotation #"
									click-to-copy
									label="barcode"
								></ShowBarcode> -->
								<v-chip
									v-if="projectTransfer && projectTransfer.transfer_projects_status"
									label
									:color="`${getStatusColor(
										projectTransfer.transfer_projects_status
									)} white--text text--darken-4 text-capitilize`"
									class="mr-2 ml-2"
								>
									<span class="text-capitalize">
										{{ projectTransfer.transfer_projects_status }}
									</span>
								</v-chip>
							</div>
						</v-flex>
						<v-flex md5 class="text-right">
							<template>
								<!-- <div class="ml-2 d-inline-flex align-items-center">
									<span class="fw-500" style="font-size: 20px">From :</span>
									<span class="blue--text text--darken-4 ml-1 fw-500" style="font-size: 20px">
										<ShowPrice :object="projectTransfer" object-key="transfer_from_name" label="Transfered From"></ShowPrice>
									</span>
								</div> -->
								
								<template>
									<v-btn
										depressed
										tile
										class="blue darken-4 mr-2 my-auto"
										color="white--text "
										v-on:click="doAction('edit')"
										v-if="projectTransfer?.status != 2"
									>
										<v-icon class="mr-1">mdi-pencil</v-icon>
										Edit
									</v-btn>

									<v-menu
										bottom
										left
										origin="center center"
										close-on-click
										close-on-content-click	
										transition="slide-y-transition"
										rounded="0"
										offset-y
										max-height="400px"
										content-class="white-background"
									>
									
										<template v-if="projectTransfer?.status != 2" v-slot:activator="{ on, attrs }">
											<v-btn
												depressed
												color="blue darken-4"
												class="text-white"
												tile
												:disabled="pageLoading"
												v-bind="attrs"
												v-on="on"
											>
												<v-icon left>mdi-chevron-down</v-icon>
												Bulk Action
											</v-btn>
										</template>
										<v-list class="py-0">
											<template v-for="(item, i) in listingBulkAction">
												<v-list-item link :key="i" v-if="item.value&&item.value!='draft' && !(item.value=='rejected' && projectTransfer.status==3 ) " v-on:click="doAction(`mark_as_${item.value}`)">
													<v-list-item-title :class="item.color+`--text text--lighten-1`"
														><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
														>{{ item.title }}</v-list-item-title
													>
												</v-list-item>
												<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
											</template>
										</v-list>
									</v-menu>
									<!-- <v-btn :disabled="!pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn> -->
									<!-- <v-menu
										bottom
										left
										origin="center center"
										transition="slide-y-transition"
										rounded="0"
										offset-y
										max-height="400px"
										:close-on-content-click="false"
										content-class="white-background"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												color="blue darken-4 text-white"
												v-on="on"
												v-bind="attrs"
												class="ml-2"
												depressed
												tile
												><v-icon small left>mdi-more mdi-flip-h</v-icon>More</v-btn
											>
											
										</template>
										<v-list class="py-0">
											<v-list-item v-on:click="doAction('duplicate')">
												<v-list-item-title>
													<v-icon left size="20" color="blue darken-4">mdi-content-copy</v-icon>
													Duplicate</v-list-item-title
												>
											</v-list-item>
											<v-list-item v-on:click="doAction('close')" v-if="quotation.status == 2 ">
												<v-list-item-title>
													<v-icon left size="20" color="red lighten-1">mdi-cancel</v-icon>
													<span class="red--text text--lighten-1"> Close </span>
												</v-list-item-title>
											</v-list-item>
											<v-divider class="m-0 p-0"></v-divider>
											<template v-if="checkPermission()">
												<v-list-item v-if="false" v-on:click="doAction('send-for-approval')">
													<v-list-item-title>
														<v-icon left size="20" color="blue darken-4">mdi-email-send-outline</v-icon>
														Send For Approval</v-list-item-title
													>
												</v-list-item>
												<template
													v-if="
														getPermission('quotation::approve') &&
														(quotation.status == 1 || quotation.status == 5)
													"
												>
													<v-divider class="m-0 p-0"></v-divider>
													<v-list-item
														v-if="quotation.status == 1 || quotation.status == 5"
														v-on:click="doAction('approve')"
													>
														<v-list-item-title>
															<v-icon left size="20" color="green">mdi-check-all</v-icon>
															Accept</v-list-item-title
														>
													</v-list-item>

													<v-divider class="m-0 p-0"></v-divider>
													<v-list-item v-on:click="doAction('reject')">
														<v-list-item-title>
															<v-icon left size="20" color="red lighten-1">mdi-cancel</v-icon>
															<span class="red--text text--lighten-1"> Reject </span>
														</v-list-item-title>
													</v-list-item>
													
												</template>
											</template>
										</v-list>
									</v-menu> -->
								</template>
								<v-btn
									v-on:click="backToListing('project-transfer')"
									color="grey lighten-3"
									class="ml-2"
									depressed
									tile
									><v-icon small left>mdi-arrow-left</v-icon>Back</v-btn
								>
							</template>
						</v-flex>
					</v-layout>
					<div class="d-flex">
						<div class="width-25">
							<div class="width-100">
								<div class="mt-2 d-flex align-items-center">
									<span class="fw-600"> From : </span>
									<span class="no-wrap">
										<ShowValue
											:object="projectTransfer"
											object-key="transfer_from_name"
											label="Transfered From"
										></ShowValue>
										<ShowBarcode
											:object="projectTransfer"
											object-key="transfer_form_barcode"
											tooltip
											tooltip-text="Transfer from #"
											click-to-copy
											label="transfer from"
										></ShowBarcode>
									</span>
								</div>
							</div>
							<div class="width-100">
								<div class="mt-2">
									<span class="fw-600"> To : </span>
									<span class="no-wrap">
										<ShowValue
											:object="projectTransfer"
											object-key="transfer_to_name"
											label="Transfered To"
										></ShowValue>
										<ShowBarcode
											:object="projectTransfer"
											object-key="transfer_to_barcode"
											tooltip
											tooltip-text="Transfer To #"
											click-to-copy
											label="transfer to"
										></ShowBarcode>
									</span>
								</div>
							</div>
							
						</div>
						
						<div class="width-25">
							<div class="width-100">
								<div class="mt-2">
									<span class="fw-600">Created by : </span>
									<span class="fw-500 no-wrap">
										<ShowValue :object="projectTransfer" object-key="created_by" label="Created by"></ShowValue>
									</span>
								</div>
							</div>
							<div class="width-100">
								<div class="mt-2">
									<span class="fw-600 mr-2">Created Date: </span>
									<span class="fw-500 no-wrap">
										{{ projectTransfer && projectTransfer.added_at }}
									</span>
								</div>
							</div>
						</div>
						<div class="width-25">
							<div class="width-100">
								<div class="mt-2">
									<span class="fw-600">Updated by : </span>
									<span class="fw-500 no-wrap">
										<ShowValue :object="projectTransfer" object-key="modified_by" label="updated by"> </ShowValue>
									</span>
								</div>
							</div>
							<div class="width-100">
								<div class="mt-2">
									<span class="fw-600">Updated Date : </span>
									<span class="fw-500 no-wrap">
										<template v-if="projectTransfer?.updated_at">

											{{ projectTransfer?.updated_at }}
											
										</template>
										<em v-else class="text-muted">no updated</em>
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="d-flex mt-3">
						<!-- <div class="width-6">
							<div class="mt-2 d-flex align-items-center">
								<span class="fw-600"> Remark : </span>
							</div>
						</div>
						<div class="width-94">
							<span class="no-wrap fw-500">
									<ShowTextAreaValue
										:object="projectTransfer"
										object-key="transfer_projects_remark"
										label="Remark"
									></ShowTextAreaValue>
							</span>
						</div> -->
						<table width="100%" class="detail-table scattered-table mt-2">
							<tr v-if="projectTransfer.transfer_projects_remark">
								<th width="90px" valign="top" class="py-1">Remark:</th>
								<td valign="top" class="py-1">
									<ShowTextAreaValue
										:object="projectTransfer"
										object-key="transfer_projects_remark"
										label="Remark"
									></ShowTextAreaValue>
								</td>
							</tr>
						</table>
					</div>
				</v-flex>
			</v-layout>
			
			<div>
				<v-tabs
					v-model="tab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#equipment_line_items">
						<inline-svg
							:fill="tab === 'equipment_line_items' ? 'white' : 'Gray'"
							:src="$assetURL('media/custom-svg/purchase-order.svg')"
							height="15"
							width="15"
							class="mr-2"
						/>Equipments</v-tab
					>
					<v-tab href="#product_line_items"
						><inline-svg
							:fill="tab === 'quotation' ? 'white' : 'Gray'"
							:src="$assetURL('media/custom-svg/quotation1.svg')"
							height="15"
							width="15"
							class="mr-2"
						/>Products</v-tab
					>

					<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon>Files</v-tab>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon>Logs</v-tab>
				</v-tabs>

				<v-tabs-items v-model="tab">
					<v-tab-item value="detail" v-if="tab === 'detail'">
						<div class="d-flex mt-3">
							<div class="overflow-y w-50" style="max-height: calc(100vh - 250px)">
								<v-layout class="mb-2 min-height-45px mb-3" style="background-color: #f1f6f9">
									<v-flex class="font-level-3-bold my-auto mx-2">
										<inline-svg
											style="width: 25px; height: 25px"
											fill="#006fc8"
											:src="$assetURL('media/custom-svg/overview3.svg')"
										/>
										Project Detail
									</v-flex>
								</v-layout>
								<div class="mx-4 mb-12">
									<div class="d-flex mb-4">
										<div class="d-flex">
											<div class="fw-600 w-150">Description</div>
											<div class="fw-500 ml-4">
												<ShowTextAreaValue
													:object="project"
													object-key="description"
													label="Project Description"
												>
												</ShowTextAreaValue>
											</div>
										</div>
									</div>
									<div class="d-flex mb-4">
										<div class="d-flex">
											<div class="fw-600 w-150">Specification</div>
											<div class="fw-500 ml-4">
												<ShowValue :object="project" object-key="specifications" label="Specification">
												</ShowValue>
											</div>
										</div>
									</div>
								</div>
								<div class="mb-12">
									<div class="d-flex mb-4">
										<div class="fw-600 w-150">Remark</div>
										<div class="fw-500 ml-4">
											<ShowTextAreaValue
												:object="project"
												object-key="bankers_guarantee_remark"
												label="Remark"
											>
											</ShowTextAreaValue>
										</div>
									</div>
								</div>
								<v-layout class="mb-2 min-height-45px mb-3 mt-12" style="background-color: #fff8d6">
									<v-flex class="font-level-3-bold my-auto mx-2">
										<span class="detail-svg-icon mr-2">
											<inline-svg
												style="width: 25px; height: 25px"
												fill="#006fc8"
												:src="$assetURL('media/custom-svg/bank.svg')"
											/>
										</span>
										Banker Guarantee
									</v-flex>
								</v-layout>
								<div class="mx-4 mb-12">
									<div class="d-flex w-100 mb-4">
										<div class="w-50 d-flex">
											<div class="fw-600 w-150">Bank Name</div>
											<div class="fw-500 ml-4">
												<ShowValue :object="project" object-key="bankers_guarantee_name" label="Bank Name">
												</ShowValue>
											</div>
										</div>
										<div class="w-50 d-flex">
											<div class="fw-600 w-150">Amount</div>
											<div class="fw-500 ml-4">
												<ShowPrice :object="project" object-key="bankers_guarantee_amount" label="Bank Amount">
												</ShowPrice>
											</div>
										</div>
									</div>
								</div>
								<v-layout class="mb-2 min-height-45px mb-3 mt-12" style="background-color: #c4dfdf">
									<v-flex class="font-level-3-bold my-auto mx-2">
										<span class="detail-svg-icon mr-2">
											<inline-svg
												style="width: 35px; height: 35px"
												fill="#006fc8"
												:src="$assetURL('media/custom-svg/insurance.svg')"
											/>
										</span>
										Insurance Bank
									</v-flex>
								</v-layout>
								<div class="mx-4 mb-12">
									<div class="d-flex w-100 mb-4">
										<div class="w-50 d-flex">
											<div class="fw-600 w-150">Insurance Bank</div>
											<div class="fw-500 ml-4">
												<ShowValue
													:object="project"
													object-key="insurance_bank_name"
													label="Insurance Bank Name"
												>
												</ShowValue>
											</div>
										</div>
										<div class="w-50 d-flex">
											<div class="fw-600 w-150">Amount</div>
											<div class="fw-500 ml-4">
												<ShowPrice
													:object="project"
													object-key="insurance_bank_amount"
													label="Insurance Amount"
												>
												</ShowPrice>
											</div>
										</div>
									</div>
									<div class="d-flex w-100">
										<div class="fw-600 w-150">Remark</div>
										<div class="fw-500 ml-4">
											<ShowTextAreaValue
												:object="project"
												object-key="insurance_bank_remark"
												label="Insurance Remark"
											>
											</ShowTextAreaValue>
										</div>
									</div>
								</div>
							</div>
							<div class="w-50 ml-8">
								<v-layout class="mb-2 min-height-45px mb-3" style="background-color: #f6ffde">
									<v-flex class="font-level-3-bold my-auto mx-2">
										<span class="detail-svg-icon mr-2">
											<inline-svg
												style="width: 30px; height: 30px"
												fill="#006fc8"
												:src="$assetURL('media/custom-svg/task_1.svg')"
											/>
										</span>
										Tasks
									</v-flex>
								</v-layout>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="transaction">
						<template v-if="tab == 'transaction'">
							<TransactionListing
								relation-type="project"
								:relation-type-id="project.id"
							></TransactionListing>
							<TransactionListing
								relation-type="project"
								:relation-type-id="project.id"
							></TransactionListing>
						</template>
					</v-tab-item>
					<v-tab-item value="purchase-order">
						<CheckoutListing
							v-if="project && project.id && tab == 'asset'"
							class="mx-4"
							relation-type="project"
							:relation-type-id="project.id"
							type="asset"
							title="Assets"
						>
						</CheckoutListing>
					</v-tab-item>

					<v-tab-item value="equipment_line_items">
						<EquipmentLineItems :line-items="line_items_equipments" class="mx-4"> </EquipmentLineItems>
					</v-tab-item>
					
					<v-tab-item value="product_line_items">
						<ProductLineItems :line-items="line_items" class="mx-4"> </ProductLineItems>
					</v-tab-item>
					<v-tab-item value="file">
						<Files
							v-if="tab == 'file'"
							class="mx-4"
							type-text="Project Files"
							type="project-transfer"
							:type-uuid="$route.params.uuid"
						>
						</Files>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="tab == 'event'"
							class="mx-4"
							type-text="Customer"
								type="project-transfer"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
				</v-tabs-items>
			</div>

			<ApproveConfirmation
				v-if="approveDialog"
				type="Project Transfer"
				v-on:success="
					approveDialog = false;
					getProjectTransfer();
				"
				v-on:close="approveDialog = false"
				:approve-dialog="approveDialog"
				status="mark_as_approved"
				:uuid="projectTransfer.uuid"
				approve-type="project-transfer"
			>
			</ApproveConfirmation>
			<RejectDialog
				v-if="rejectDialog"
				type="Project Transfer"
				v-on:success="
					rejectDialog = false;
					getProjectTransfer();
				"
				v-on:close="rejectDialog = false"
				:reject-dialog="rejectDialog"
				status="mark_as_rejected"
				:uuid="projectTransfer.uuid"
				reject-type="project-transfer"
			>
			</RejectDialog>
			<!-- <RemarkRejectDialog
				v-if="RemarkDialogNew"
				type="Remarks"
				v-on:success="
					RemarkDialogNew = false;
					getProjectTransfer();
				"
				v-on:close="RemarkDialogNew = false"
				:reject-dialog="RemarkDialogNew"
				:rejctremark="purchase_order.reject_remark"
				:uuid="projectTransfer.uuid"
				reject-type="request-order"
			>
			</RemarkRejectDialog> -->

		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ShowValue from "@/view/components/ShowValue";
import ShowPrice from "@/view/components/ShowPrice";
import EventListing from "@/view/components/EventListing";
import CheckoutListing from "@/view/components/CheckoutListing";
import TransactionListing from "@/view/components/TransactionListing";
import Files from "@/view/components/Files";
// import BudgetListing from "@/view/components/BudgetListing";
import EquipmentLineItems from "@/view/components/EquipmentLineItems";
import ProductLineItems from "@/view/components/ProductLineItems";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
import { GetProjectTransfer,ChangeProjectStatus } from "@/core/lib/project-transfer.lib.js";
import ShowBarcode from "@/view/components/ShowBarcode";
import { SET_ERROR } from "@/core/services/store/common.module";
import ApproveConfirmation from "@/view/components/ApproveConfirmation";
import RejectDialog from "@/view/components/RejectDialog";
export default {
	name: "project-transfer-detail",
	title: "Detail Transfer Detail",
	data() {
		return {
			uuid: null,
			barcode: null,
			customer: null,
			purchase_order: null,
			property: {},
			activateText: null,
			activateURL: null,
			deactivateText: null,
			deactivateURL: null,
			deleteText: null,
			contact_person: [],
			startProjectDialog: false,
			completeProjectDialog: false,
			formValid: true,
			deleteDialog: false,
			deactivateDialog: false,
			pageLoading: true,
			contentLoaded: true,
			// tab: "detail",
			approveDialog:false,
			rejectDialog:false,
			line_items:[],
			line_items_equipments:[],
			projectTransfer: null,
			project: {
				customer: null,
				purchase_order: null,
				name: null,
				quote: null,
				reference_no: null,
				description: null,
				start_date_formatted: null,
				end_date_formatted: null,
				remark: null,
				banker_guarantee_amount: null,
				banker_guarantee_remark: null,
			},
			listingBulkAction: [
				{
					id: 75,
					type: "project",
					title: "Mark as Draft",
					value: "draft",
					color: "blue",
					icon: "mdi-check-all",
					bulk: true,
					order: 1,
				},
				{
					id: 76,
					type: "project",
					title: "Mark as Approved",
					value: "approved",
					color: "green",
					icon: "mdi-check-all",
					bulk: true,
					order: 2,
				},
				{
					id: 77,
					type: "project",
					title: "Mark as Rejected",
					value: "rejected",
					color: "red",
					icon: "mdi-check-all",
					bulk: true,
					order: 3,
				},
			],
		};
	},
	methods: {
		
		async changeProjectStatus(type) {
			try {
				this.pageLoading = true;
				const param = {
					action: type,
					selected: [this.$route.params.uuid],
				};
				await ChangeProjectStatus(param);
				await this.getProjectTransfer();

			} catch (error) {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
				
			}
		},
		doAction(param) {
			
			switch (param) {
				case "edit":
					this.$router.push({
						name: "project-transfer-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;

				case "print-label":
					break;
				case "mark_as_approved":
					this.approveDialog = true;
					break;
				case "mark_as_rejected":
					this.rejectDialog = true;
					break;
			}
		},
		getProjectTransfer() {
			const { uuid } = this.$route.params;
			//this.uuid = uuid;
			GetProjectTransfer(uuid)
				.then((data) => {
					
					this.projectTransfer = data.project_transfer;
					this.line_items = data.line_items;

					const filterLineItem = this.line_items.filter((item) => item.product_type=='equipments');
					this.line_items_equipments = filterLineItem.map((ele)=> {
						return { ...ele};
					});
					const filterLineItem1 = this.line_items.filter((item) => item.product_type=='inventory');
					this.line_items = filterLineItem1.map((ele)=> {
						return { ...ele};
					});

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Project Transfer", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: data.project_transfer.transfer_projects_barcode, disabled: true },
					]);

					//console.log(filterLineItem1);

					//console.log(this.projectTransfer);
					//console.log(this.projectTransfer);
					//this.activateURL = `project/${data.uuid}/activate`;
					//this.deactivateURL = `project/${data.uuid}/de-activate`;
					// this.$store.dispatch(SET_BREADCRUMB, [
					// 	{ text: "Project Transfer", disabled: true },
					// 	{ text: this.barcode, disabled: true },
					// ]);
				})
				.catch((error) => {
					// this.goBack();
					this.logError(error);
				})
				.finally(() => {
					//this.pageLoading = true;
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
	},
	computed: {
		tab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.tab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "detail";
			},
		},
	},
	components: {
		Files,
		ShowValue,
		ShowPrice,
		ShowTextAreaValue,
		RejectDialog,
		ApproveConfirmation,
		// BudgetListing,
		ProductLineItems,
		EventListing,
		ShowBarcode,
		CheckoutListing,
		TransactionListing,
		EquipmentLineItems,
	},
	async mounted() {
		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Project Transfer", disabled: true },
			{ text: "Detail", disabled: true },
		]);
		// const data = await GetProjectTransfer(uuid);
		// this.projectTransfer = data.project_transfer;

		await this.getProjectTransfer();
		//this.projectTransfer = Data;
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
